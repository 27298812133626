import { DateTime } from 'luxon';

type InputDate = Date | null | undefined;

const formatDate = (
	date: InputDate,
	formattingFunction: (
		date: Date,
		locales?: Intl.LocalesArgument,
		options?: Intl.DateTimeFormatOptions
	) => string,
	options?: Intl.DateTimeFormatOptions
) =>
	date instanceof Date
		? formattingFunction(date, window.navigator.language, options)
		: '';

export const getLocalizedTimeFormat = (
	inputDate: InputDate,
	options?: Intl.DateTimeFormatOptions
) =>
	formatDate(
		inputDate,
		(date, locale, options) => date.toLocaleTimeString(locale, options),
		options
	);

export const getLocalizedDateFormat = (
	inputDate: InputDate,
	options?: Intl.DateTimeFormatOptions
) =>
	formatDate(
		inputDate,
		(date, locale, options) => date.toLocaleDateString(locale, options),
		options
	);

export const getLocalizedDateTimeFormat = (
	inputDate: InputDate,
	options?: Intl.DateTimeFormatOptions
) =>
	formatDate(
		inputDate,
		(date, locale, options) => date.toLocaleString(locale, options),
		options
	);

export const fromIsoToLuxonDate = (iso: string) =>
	DateTime.fromISO(iso, {
		setZone: true,
		zone: 'utc',
	});

export const fromIsoToDate = (iso: string) =>
	fromIsoToLuxonDate(iso).toJSDate();
