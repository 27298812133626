import { EndConsumerDto } from 'api';

export type ConsumerType = 'PrivatePerson' | 'Company';

export type ConsumerUnitConnectionType = 'Owner' | 'Operator';

export interface EndCustomerDto extends EndConsumerDto {}

export function getFullName(customer: EndCustomerDto | null | undefined) {
	if (customer == null) {
		return '';
	}

	return `${customer.firstName || ''} ${customer.surNameOrLastName || ''}`;
}

export function getPhoneNumberWithPrefix(
	customer: EndCustomerDto | null | undefined
) {
	if (customer == null) {
		return '';
	}

	if (!customer.phonePrefix) {
		return customer.phoneNumber || '';
	}

	return `${customer.phonePrefix || ''} ${customer.phoneNumber || ''}`;
}

export const hasOwnerAndOperatorSameEmail = (
	owner: EndCustomerDto | null,
	operator: EndCustomerDto
) =>
	owner?.isConsumerAgreeForPentaStorageOfUserInformation === true &&
	operator.isConsumerAgreeForPentaStorageOfUserInformation &&
	owner.email === operator.email;
