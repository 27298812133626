import { Box } from '@mui/material';
import { ChangeEventHandler, DragEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FileUploadButton } from './FileUploadButton';

export const FileDragBox = ({
	disabled = false,
	onNewFilesUploaded,
}: {
	disabled?: boolean;
	onNewFilesUploaded: (newFiles: File[]) => void;
}) => {
	const { t } = useTranslation(['component']);
	const [isDraggedOver, setIsDraggedOver] = useState(false);

	const mergeFiles = (newFiles: FileList) => {
		const newFilesArray = Array.from(newFiles);
		onNewFilesUploaded(newFilesArray);
	};

	const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
		const changedFileList = event.target?.files;

		if (changedFileList) {
			mergeFiles(changedFileList);

			// Clear the input file list, so that removing will work
			const dt = new DataTransfer();
			event.target.files = dt.files;
		}
	};

	const handleDrop: DragEventHandler<HTMLDivElement> = (event) => {
		setIsDraggedOver(false);
		const changedFileList = event.dataTransfer.files;

		if (changedFileList) {
			mergeFiles(changedFileList);
		}

		event.preventDefault();
		event.stopPropagation();
	};

	const handleDragEnter: DragEventHandler<HTMLDivElement> = (event) => {
		event.stopPropagation();
		event.preventDefault();
	};

	const handleDragOver: DragEventHandler<HTMLDivElement> = (event) => {
		if (!isDraggedOver) {
			setIsDraggedOver(true);
		}

		event.stopPropagation();
		event.preventDefault();
	};

	const handleDragLeave = () => {
		if (isDraggedOver) {
			setIsDraggedOver(false);
		}
	};

	return (
		<Box
			bgcolor={isDraggedOver ? 'background.blue' : 'lightGray'}
			display="flex"
			justifyContent="center"
			alignItems="center"
			margin={(theme) => ({
				xs: theme.spacing(0, -2, -4, -2),
				sm: theme.spacing(-2, -2, -4, 0),
				md: theme.spacing(-2, -2, -4, 0),
			})}
			minHeight={128}
			onDrop={handleDrop}
			onDragEnter={handleDragEnter}
			onDragOver={handleDragOver}
			onDragLeave={handleDragLeave}
		>
			<FileUploadButton
				disabled={disabled}
				id="file-drag-box"
				onChange={handleChange}
				multiple={true}
				sx={(theme) => ({
					borderRadius: theme.spacing(2.5),
				})}
			>
				{t('component:file-drag-box-button-label')}
			</FileUploadButton>
		</Box>
	);
};
