import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TFunction } from 'i18next';

export const getHelperText = (
	t: TFunction,
	disabled: boolean | undefined,
	error: FieldError | undefined,
	helperText?: React.ReactNode
) =>
	// Casting as 'error-unexpected' is workaround, so that typescript won't throw error.
	// Default value is not passed, so that custom translation of error message can be passed here.
	!disabled && error?.message
		? t(error.message as 'error-unexpected')
		: helperText;

export const displayError = (
	disabled: boolean | undefined,
	error: FieldError | undefined
): boolean => !disabled && Boolean(error);

export const useDatePickerFutureValidation = (
	additionalValidation: (value: DateTime) => string | boolean = () => true
) => {
	const { t } = useTranslation(['common', 'error']);

	const validation = useMemo(
		() => ({
			required: t('validation-error-message'),
			validate: (value: DateTime) =>
				value > DateTime.now()
					? t('error:no-future-date')
					: additionalValidation(value),
		}),
		[additionalValidation]
	);

	return { validation };
};
