import { useParams } from 'react-router-dom';

export const useNumberParam = (paramName = 'unitId'): number => {
	const params = useParams();
	const unitIdParsed = Number(params[paramName]);

	// to check if it's an integer number excluding float
	if (isNaN(unitIdParsed) || unitIdParsed % 1 !== 0) {
		throw Error('Id is not a number');
	}

	return unitIdParsed;
};
