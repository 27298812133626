import {
	ActionReducerMapBuilder,
	PayloadAction,
	SliceCaseReducers,
	ValidateSliceCaseReducers,
	createSlice,
} from '@reduxjs/toolkit';

import { FileData, FileUploadState } from './Types';

export const initialFileState: FileUploadState = {
	attachmentFolderName: '',
	filesUploaded: [],
};

export const createFileUploadSlice = <
	State extends FileUploadState,
	CaseReducers extends SliceCaseReducers<State>,
>({
	name,
	initialState,
	reducers,
	extraReducers,
}: {
	name: string;
	initialState: State;
	reducers: ValidateSliceCaseReducers<State, CaseReducers>;
	extraReducers?: (builder: ActionReducerMapBuilder<State>) => void;
}) => {
	return createSlice({
		name,
		initialState,
		reducers: {
			attachmentFolderCreated: (
				state,
				{ payload }: PayloadAction<string>
			) => {
				if (payload) {
					state.attachmentFolderName = payload;
				}
			},
			fileUploaded: (state, { payload }: PayloadAction<FileData>) => {
				state.filesUploaded.push(payload);
			},
			fileDeleted: (state, { payload }: PayloadAction<string>) => {
				const newFileUploaded = state.filesUploaded.filter(
					(file) => file.name !== payload
				);

				return { ...state, filesUploaded: newFileUploaded };
			},
			...reducers,
		},
		extraReducers,
	});
};
