import { createEntityAdapter } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import { FileState, FileUploadState } from './Types';

export const isSameFile = (a: File, b: File) => a.name === b.name;

export const createEmptyFilteredList = () =>
	cloneDeep({
		TooMany: [],
		TooBig: [],
		WrongExtension: [],
		AlreadyExists: [],
	});

export const fileDataAdapter = createEntityAdapter<FileUploadState, string>({
	selectId: (fileData) => fileData.file.name,
	sortComparer: (a, b) => a.file.name.localeCompare(b.file.name),
});

export const fileDataSelectors = fileDataAdapter.getSelectors();

export const initialState: FileState = {
	fileList: fileDataAdapter.getInitialState(),
	filteredList: null,
};
