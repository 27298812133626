import { AxiosError } from 'axios';
import { pull } from 'lodash';
import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';

import { NewFilesActionParams, UploadControllerParams } from '.';
import { getReducer } from './getReducer';
import { fileDataSelectors, initialState } from './utils';

const defaultMaxFileSize = 10485760;
const defaultMaxFileCount = 10;
const defaultAllowedExtensions = /\.([0-9A-Za-z]*)$/;

/**
 * A hook for controlled upload of multiple files.
 * @param {UploadControllerParams} params - {@link UploadControllerParams} Parameters for hook.
 */
export function useUploadController({
	fileUploaded,
	uploadFile,
	fileExistsAlready,
	maxFileSize,
	maxFileCount,
	allowedExtensions,
}: UploadControllerParams) {
	const uploadingFilenames = useRef<string[]>([]);

	const reducerProps = useMemo(
		() => ({
			maxFileSize: maxFileSize || defaultMaxFileSize,
			maxFileCount: maxFileCount || defaultMaxFileCount,
			allowedExtensions: allowedExtensions || defaultAllowedExtensions,
			fileExistsAlready: fileExistsAlready,
		}),
		[maxFileSize, maxFileCount, allowedExtensions, fileExistsAlready]
	);

	const [fileState, fileDispatch] = useReducer(
		getReducer(reducerProps),
		initialState
	);

	const handleDeleteFile = useCallback((file: File) => {
		fileDispatch({ type: 'fileDeleted', fileToBeDeleted: file });
	}, []);

	const handleNewFiles: (params: NewFilesActionParams) => void = useCallback(
		(params) => {
			uploadingFilenames.current.push(
				...params.newFiles.map(({ name }) => name)
			);
			fileDispatch({ type: 'newFiles', ...params });
		},
		[]
	);

	const handleRetryUpload = useCallback((file: File) => {
		uploadingFilenames.current.push(file.name);
		fileDispatch({ type: 'retryUpload', file });
	}, []);

	const handleWarningDialogClose = useCallback(() => {
		fileDispatch({ type: 'filteredFilesCleared' });
	}, []);

	const handleProgressUpdate = useCallback(
		(file: File, percentCompleted: number) => {
			fileDispatch({
				type: 'progressUpdate',
				file,
				percentCompleted,
			});
		},
		[]
	);

	const uploadFileLogic = useCallback(
		(file: File) => {
			uploadFile(file)
				.then((fulfilled) => {
					fileDispatch({
						type: 'uploadFinished',
						file,
					});

					fileUploaded instanceof Function &&
						fileUploaded(file, fulfilled);
				})
				.catch((reason) => {
					const errorReason = reason as AxiosError;

					if (errorReason?.response?.status === 409) {
						fileDispatch({
							type: 'uploadFinished',
							file: file,
						});
						return;
					}

					fileDispatch({
						type: 'uploadFailed',
						file: file,
						reason: errorReason,
					});
				})
				.finally(() => {
					pull(uploadingFilenames.current, file.name);
				});
		},
		[uploadFile, fileUploaded]
	);

	useEffect(() => {
		for (const fileData of fileDataSelectors.selectAll(
			fileState.fileList
		)) {
			if (fileData.state === 'idle') {
				uploadFileLogic(fileData.file);
				fileDispatch({ type: 'uploadStarted', file: fileData.file });
			}
		}
	}, [uploadFileLogic, fileState.fileList]);

	return {
		filteredList: fileState.filteredList,
		selectAllFiles: fileDataSelectors.selectAll(fileState.fileList),
		handleDeleteFile,
		handleNewFiles,
		handleRetryUpload,
		handleProgressUpdate,
		handleWarningDialogClose,
		uploadingFilenames: uploadingFilenames.current,
	};
}
