import { Box, Paper, Typography, useTheme } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

import { Removable, Sxable } from 'library/types';

export const DrivelineWrapper = ({
	children,
	isRemoved,
	sx,
}: PropsWithChildren<Removable & Sxable>) => (
	<Paper
		elevation={0}
		sx={[
			({ palette: { grey } }) => ({
				my: 1,
				p: 2,
				position: 'relative',
				backgroundColor: isRemoved ? grey[100] : 'background.blue',
				pointerEvents: isRemoved ? 'none' : 'all',
			}),
			...(Array.isArray(sx) ? sx : [sx]),
		]}>
		{children}
	</Paper>
);

type DrivelineHeaderProps = {
	actions?: ReactNode;
	title: ReactNode;
};

export const DrivelineHeader = ({
	actions,
	title,
}: DrivelineHeaderProps): JSX.Element => {
	const { spacing } = useTheme();

	return (
		<Box display="flex" alignItems="center" pb={1}>
			<Box
				display="inline-flex"
				justifyContent="space-between"
				gap={1}
				width={1}
				alignItems={{ xs: 'center' }}
				flexDirection={{ sm: 'row' }}>
				<Typography
					variant="h6"
					display="flex"
					flexWrap="wrap"
					flex={1}
					overflow="hidden"
					gap={spacing(0.5)}>
					{title}
				</Typography>
				{actions && (
					<Box
						display="inline-flex"
						alignItems={{ xs: 'unset', sm: 'center' }}
						flexDirection={{ xs: 'column', sm: 'row' }}
						gap={1}>
						{actions}
					</Box>
				)}
			</Box>
		</Box>
	);
};

export const DrivelineContent = ({
	children,
	sx = {},
}: PropsWithChildren & Sxable) => (
	<Box
		sx={[
			(theme) => ({
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'wrap',
				gap: theme.spacing(2),

				[theme.breakpoints.only('xs')]: {
					'& > *': {
						flex: '1 1 auto',
					},
				},
			}),
			...(Array.isArray(sx) ? sx : [sx]),
		]}>
		{children}
	</Box>
);
