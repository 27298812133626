import { TextField } from '@mui/material';
import { RefCallback, forwardRef } from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IMaskInput } from 'react-imask';

import { TextFieldElementProps } from './ControlledTextField';
import { displayError, getHelperText } from './functions';

// inputProps are omitted to not override imaskProps, use imaskProps to pass props to input
export interface ControlledFormattedTextFieldProps<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<
		TextFieldElementProps<TFieldValues, TName>,
		'inputProps' | 'onBlur'
	> {
	// not possible to get IMaskProps type, so it is left with any for now
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	imaskProps?: any;
}

interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
	mask: string;
	placeholderChar?: string;
	lazy: boolean;
	unmask: boolean;
}

const iMaskDefinitions = {
	'#': /[0-9,A-Z,a-z]/,
};

const TextMaskCustom = forwardRef<HTMLElement, CustomProps>(
	function TextMaskCustom(props, ref) {
		const { onChange, ...other } = props;

		return (
			<IMaskInput
				{...other}
				definitions={iMaskDefinitions}
				inputRef={(el) => {
					(ref as RefCallback<HTMLElement>)(el as never);
				}}
				onAccept={(value, maskRef) => {
					// if user deleted value, then send empty string, because value contains mask
					const correctValue =
						maskRef.unmaskedValue !== '' ? value : null;

					onChange({
						target: {
							name: props.name,
							value: correctValue as string,
						},
					});
				}}
				overwrite
			/>
		);
	}
);

export const ControlledFormattedTextField = <
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
	id,
	control,
	name,
	validation = {},
	imaskProps,
	required,
	disabled,
	helperText,
	...rest
}: ControlledFormattedTextFieldProps<TFieldValues, TName>): JSX.Element => {
	const { t } = useTranslation();

	if (Boolean(validation.required) && !required) {
		required = true;
	}

	const {
		field: { onChange, onBlur, value, ref },
		fieldState: { error },
	} = useController<TFieldValues, TName>({
		name,
		control,
		rules: validation,
	});

	return (
		<TextField
			id={id}
			name={name}
			value={value ?? ''}
			onChange={onChange}
			onBlur={onBlur}
			ref={ref}
			error={displayError(disabled, error)}
			helperText={getHelperText(t, disabled, error, helperText)}
			required={required}
			disabled={disabled}
			InputProps={{
				inputComponent: TextMaskCustom as never,
			}}
			inputProps={{
				name,
				required,
				disabled,
				lazy: !(imaskProps?.placeholderChar && Boolean(value)),
				...imaskProps,
			}}
			{...rest}
		/>
	);
};
