import { findIndex, first } from 'lodash';

import {
	Action,
	FileState,
	FileUploadState,
	FileUploadStateList,
	FilteredList,
	ReducerProps,
} from '.';
import {
	createEmptyFilteredList,
	fileDataAdapter,
	fileDataSelectors,
	isSameFile,
} from './utils';

export function getReducer(props: ReducerProps) {
	return (state: FileState, action: Action): FileState => {
		switch (action.type) {
			case 'uploadStarted':
				const uploadState = fileDataAdapter.updateOne(state.fileList, {
					id: action.file.name,
					changes: { state: 'uploading' },
				});
				return { ...state, fileList: uploadState };
			case 'uploadFinished':
				const finishedState = fileDataAdapter.updateOne(
					state.fileList,
					{
						id: action.file.name,
						changes: { state: 'finished' },
					}
				);
				return { ...state, fileList: finishedState };
			case 'uploadFailed':
				const failedState = fileDataAdapter.updateOne(state.fileList, {
					id: action.file.name,
					changes: { state: 'failed', failReason: action.reason },
				});
				return { ...state, fileList: failedState };
			case 'progressUpdate':
				const progressState = fileDataAdapter.updateOne(
					state.fileList,
					{
						id: action.file.name,
						changes: {
							percentCompleted: action.percentCompleted,
						},
					}
				);
				return { ...state, fileList: progressState };
			case 'newFiles': {
				const { filesThatWillBeSkipped, correctFiles } = filterNewFiles(
					state.fileList,
					action.newFiles,
					props,
					action.externalFileCount
				);

				const anyFilesSkipped =
					first(filesThatWillBeSkipped.AlreadyExists) ||
					first(filesThatWillBeSkipped.TooBig) ||
					first(filesThatWillBeSkipped.TooMany) ||
					first(filesThatWillBeSkipped.WrongExtension);

				const newFilesState = fileDataAdapter.addMany(
					state.fileList,
					correctFiles
				);

				return {
					fileList: newFilesState,
					filteredList: anyFilesSkipped
						? filesThatWillBeSkipped
						: null,
				};
			}
			case 'retryUpload': {
				const retriedState = fileDataAdapter.updateOne(state.fileList, {
					id: action.file.name,
					changes: { state: 'idle' },
				});
				return { ...state, fileList: retriedState };
			}
			case 'fileDeleted': {
				const removedState = fileDataAdapter.removeOne(
					state.fileList,
					action.fileToBeDeleted.name
				);
				return { ...state, fileList: removedState };
			}
			case 'filteredFilesCleared': {
				return { ...state, filteredList: null };
			}
			default:
				throw new Error();
		}
	};
}

function filterNewFiles(
	fileList: FileUploadStateList,
	newFiles: File[],
	props: ReducerProps,
	externalFileCount?: number
) {
	const filesThatWillBeSkipped: FilteredList = createEmptyFilteredList();
	let correctFiles: FileUploadState[] = [];

	for (const file of newFiles) {
		if (props.fileExistsAlready instanceof Function) {
			if (props.fileExistsAlready(file)) {
				filesThatWillBeSkipped['AlreadyExists'].push(file);
				continue;
			}
		} else if (
			findIndex(
				fileDataSelectors.selectAll(fileList),
				(existingFileData) => isSameFile(existingFileData.file, file)
			) >= 0
		) {
			filesThatWillBeSkipped['AlreadyExists'].push(file);
			continue;
		}

		if (!file.name.toLowerCase().match(props.allowedExtensions)) {
			filesThatWillBeSkipped['WrongExtension'].push(file);
			continue;
		}

		if (file.size === 0) {
			continue;
		}

		if (file.size > props.maxFileSize) {
			filesThatWillBeSkipped['TooBig'].push(file);
			continue;
		}

		correctFiles.push({
			file,
			state: 'idle',
			percentCompleted: 0,
		});
	}

	const maxFileCountThatCanBeTaken =
		props.maxFileCount -
		fileDataSelectors.selectTotal(fileList) -
		(externalFileCount ?? 0);

	if (maxFileCountThatCanBeTaken < correctFiles.length) {
		filesThatWillBeSkipped['TooMany'] = correctFiles
			.slice(maxFileCountThatCanBeTaken)
			.map((fileData) => fileData.file);
		correctFiles = correctFiles.slice(0, maxFileCountThatCanBeTaken);
	}

	return {
		filesThatWillBeSkipped,
		correctFiles,
	};
}
