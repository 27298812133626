import { Box, Button, Divider, Theme, useMediaQuery } from '@mui/material';
import { DataType } from 'csstype';
import { noop } from 'lodash';
import { memo, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useDoubleClick from 'use-double-click';

import { useWizard } from './useWizard';

export interface WizardButtonsProps {
	additionalButtons?: JSX.Element[];
	additionalButtonsPosition?: Extract<
		DataType.ContentPosition,
		'end' | 'start'
	>;
	backButtonAction?: () => void;
	isBackButtonDisabled?: boolean;
	isNextButtonSubmit?: boolean;
	nextButtonFormId?: string;
	nextButtonLabel?: string;
	onSubmit?: () => void;
}

export const WizardButtons = ({
	additionalButtons = [],
	additionalButtonsPosition = 'end',
	backButtonAction,
	nextButtonLabel,
	nextButtonFormId,
	isBackButtonDisabled = false,
	isNextButtonSubmit = false,
	onSubmit,
}: WizardButtonsProps): JSX.Element => {
	const { t } = useTranslation('commonButton');
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.only('xs')
	);

	const {
		hasPreviousStep,
		goToNextStep,
		gotoPreviousStep,
		skipStep,
		isCurrentStepOptional,
	} = useWizard();
	const buttonRef = useRef<HTMLButtonElement>(null);

	useDoubleClick({
		onSingleClick: isNextButtonSubmit ? noop : onSubmit || goToNextStep,
		onDoubleClick: noop,
		ref: buttonRef,
		latency: 200,
	});

	const handleBack = useCallback(() => {
		if (gotoPreviousStep()) {
			typeof backButtonAction === 'function' && backButtonAction();
		}
	}, []);

	const buttonSize = useMemo(
		() => (isMobile ? 'medium' : 'large'),
		[isMobile]
	);
	const allAdditionalButtons = useMemo(
		() =>
			isCurrentStepOptional
				? [
						<Button
							key="skip-button"
							onClick={skipStep}
							variant="outlined"
							color="secondary"
							size={buttonSize}>
							{t('skip')}
						</Button>,
						...additionalButtons,
					]
				: additionalButtons,
		[additionalButtons, buttonSize, isCurrentStepOptional, skipStep]
	);

	return (
		<Box>
			<Divider></Divider>
			<Box
				display="flex"
				flexDirection="row"
				gap={2}
				pt={2}
				justifyContent="space-between">
				<Box display="flex" flexDirection="row" gap={2}>
					<Button
						variant="outlined"
						color="secondary"
						size={buttonSize}
						disabled={!hasPreviousStep || isBackButtonDisabled}
						onClick={handleBack}
						sx={{ mr: 'auto' }}>
						{t('back')}
					</Button>
					{additionalButtonsPosition === 'start' &&
						allAdditionalButtons}
				</Box>
				<Box display="flex" flexDirection="row" gap={2}>
					{additionalButtonsPosition === 'end' &&
						allAdditionalButtons}
					<Button
						ref={buttonRef}
						variant="contained"
						color="secondary"
						size={buttonSize}
						type={isNextButtonSubmit ? 'submit' : undefined}
						form={
							isNextButtonSubmit ? nextButtonFormId : undefined
						}>
						{nextButtonLabel || t('next')}
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default memo(WizardButtons);
