import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ErrorDialog, FileIcon } from 'library/components/controls';
import { Sxable } from 'library/types';

import { FilteredList } from '.';

const ALLOWED_EXTENSIONS =
	'.pdf .doc .docx .xls .xlsx .gif .png .jpg .jpeg .tiff .tif .slk .vlr';
const ALLOWED_SIZE = 10;
const ALLOWED_AMOUNT = 10;

interface FileWarningDialogProps extends Sxable {
	amount?: number;
	extensions?: string;
	filteredFiles: FilteredList;
	onClose: () => void;
	open?: boolean;
	size?: number;
}

export const FileWarningDialog = ({
	amount,
	extensions,
	filteredFiles,
	onClose,
	open = true,
	size,
	sx,
}: FileWarningDialogProps) => {
	const { t } = useTranslation('component');

	return (
		<ErrorDialog
			title={t('upload-files-error-dialog-title')}
			onClose={onClose}
			open={open}
			sx={sx}>
			<List>
				<WarningFileSection
					text={t('upload-files-error-dialog-subtitle-duplicates')}
					files={filteredFiles['AlreadyExists']}
				/>
				<WarningFileSection
					text={t('upload-files-error-dialog-subtitle-size-limit', {
						size: size || ALLOWED_SIZE,
					})}
					files={filteredFiles['TooBig']}
				/>
				<WarningFileSection
					text={t('upload-files-error-dialog-subtitle-extensions', {
						extensions: extensions || ALLOWED_EXTENSIONS,
					})}
					files={filteredFiles['WrongExtension']}
				/>
				<WarningFileSection
					text={t('upload-files-error-dialog-subtitle-count-limit', {
						amount: amount || ALLOWED_AMOUNT,
					})}
					files={filteredFiles['TooMany']}
				/>
			</List>
		</ErrorDialog>
	);
};

export const WarningFileSection = ({
	text,
	files,
}: {
	text: string;
	files: File[];
}): JSX.Element => {
	if (files.length === 0) {
		return <></>;
	}
	return (
		<>
			<ListItemText
				key={text}
				primary={text}
				primaryTypographyProps={{
					fontWeight: 'bold',
				}}></ListItemText>
			{files.map((file) => (
				<ListItem key={file.name}>
					<ListItemIcon>
						<FileIcon mimeType={file.type} color="secondary" />
					</ListItemIcon>
					<ListItemText primary={file.name} />
				</ListItem>
			))}
		</>
	);
};
