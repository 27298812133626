import { EmailOutlined, PhoneOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
	getFullName,
	getPhoneNumberWithPrefix,
} from 'api/responses/models/AddEndCustomer';
import UserIcon from 'assets/volvoIcons/People/UserIcon';
import { EmailLink, PhoneNumberLink } from 'library/components/link';
import { LocationText } from 'modules/Shared/Components/OperatingLocation/LocationText';

import { Customer } from '../State/Types';

export interface CustomerBoxProps {
	customer: Customer | null | undefined;
	isOwner: boolean;
}

export const CustomerBox = ({
	customer,
	isOwner,
}: CustomerBoxProps): JSX.Element => {
	const { t } = useTranslation('customer');
	const header = isOwner ? t('owner-label') : t('operator-label');
	const phoneNumber = getPhoneNumberWithPrefix(customer);

	return (
		<>
			<UserIcon sx={{ display: 'block' }} />
			<Typography sx={{ display: 'block' }} variant="caption">
				{header} {!isOwner && `(${t('optional')})`}
			</Typography>

			{isOwner && customer == null && (
				<Typography variant="h5" color={'grey.400'}>
					{t('not-assigned')}
				</Typography>
			)}

			{!isOwner && customer == null && (
				<Typography variant="body2" color={'grey.400'}>
					{t('unit-manager-description')}
				</Typography>
			)}

			{customer && (
				<Box
					sx={{
						mt: 0.5,
					}}>
					{(customer.isConsumerAgreeForPentaStorageOfUserInformation ||
						!!customer.id) && (
						<Box>
							<Typography component="p" variant="h6">
								{getFullName(customer)}
							</Typography>
							{customer.companyName != null && (
								<Typography component="p" variant="body1">
									{customer.companyName}
								</Typography>
							)}
							<Typography
								component="p"
								variant="body1"
								textOverflow="ellipsis"
								overflow="hidden">
								<EmailOutlined
									fontSize="small"
									sx={{
										mr: 0.5,
										verticalAlign: 'bottom',
									}}
								/>
								<EmailLink email={customer.email} />
							</Typography>
							<Typography
								component="p"
								variant="body1"
								textOverflow="ellipsis"
								overflow="hidden">
								<PhoneOutlined
									fontSize="small"
									sx={{
										mr: 0.5,
										verticalAlign: 'bottom',
									}}
								/>
								<PhoneNumberLink phoneNumber={phoneNumber} />
							</Typography>
							<Typography component="p" variant="body1">
								{customer.address}
							</Typography>
						</Box>
					)}
					<Typography component="p" variant="body1">
						{customer.zipCode} {customer.city}
					</Typography>
					<Typography component="p" variant="body1">
						<LocationText countryCode={customer.countryCode} />
					</Typography>
				</Box>
			)}
		</>
	);
};
