import { TableView } from '@mui/icons-material';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DescriptionIcon from '@mui/icons-material/Description';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AreaName } from 'domain/constants';
import {
	calculationsUrl,
	calculatorUrl,
	publicationsUrl,
	swkUrl,
} from 'library/components/link';
import { useBlockClick } from 'library/hooks';
import { OperationType } from 'library/models/enums/OperationType';
import { createArea } from 'library/utils/helpers/AreaHelper';
import Authorize from 'main/auth/Authorize';

type AppButtonProps = {
	url: string;
	label: string;
	icon: React.ReactNode;
};

const AppButton = ({ url, label, icon }: AppButtonProps): JSX.Element => {
	const { disabled, handleClick } = useBlockClick();

	return (
		<Button
			sx={{
				m: 1,
			}}
			className="appsContainer__app"
			href={url}
			variant="outlined"
			color="primary"
			endIcon={
				!disabled ? (
					icon
				) : (
					<CircularProgress size="1em" color="inherit" />
				)
			}
			disabled={disabled}
			onClick={handleClick}>
			{label}
		</Button>
	);
};

const SubApps = (): JSX.Element => {
	const { t } = useTranslation(['home']);

	return (
		<>
			<Grid item xs={12}>
				<Typography py={2}>
					{t('home:content-subapplications')}
				</Typography>
			</Grid>
			<Grid
				container
				direction="row"
				justifyContent="center"
				alignItems="center">
				<Authorize
					area={createArea(
						AreaName.SearchPublication,
						OperationType.Read
					)}
					redirectWhenNotAuthorized={false}
					displayIntermediatePages={false}>
					<AppButton
						url={publicationsUrl}
						label={t('home:subapps-publications')}
						icon={<DescriptionIcon />}
					/>
				</Authorize>
				<Authorize
					area={createArea(
						AreaName.MaintenancePlanner,
						OperationType.Read
					)}
					redirectWhenNotAuthorized={false}
					displayIntermediatePages={false}>
					<Button
						component={Link}
						to={'/MaintenancePlanner'}
						sx={{
							m: 1,
						}}
						className="appsContainer__app"
						variant="outlined"
						color="primary"
						endIcon={<CalendarTodayIcon />}>
						{t('home:subapps-maintenancePlanner')}
					</Button>
				</Authorize>
				<Authorize
					area={createArea(
						AreaName.ServiceCalculation_Search,
						OperationType.Write
					)}
					redirectWhenNotAuthorized={false}
					displayIntermediatePages={false}>
					<AppButton
						url={calculatorUrl}
						label={t('home:subapps-serviceCalculator')}
						icon={<TableView />}
					/>
				</Authorize>
				<Authorize
					area={createArea(
						AreaName.ServiceCalculation_CalculationWizard,
						OperationType.Read
					)}
					redirectWhenNotAuthorized={false}
					displayIntermediatePages={false}>
					<AppButton
						url={calculationsUrl}
						label={t('home:subapps-myCalculations')}
						icon={<BookmarksIcon />}
					/>
				</Authorize>
				<Authorize
					area={createArea(
						AreaName.ShowOrderButtonOnOrderPage,
						OperationType.Read
					)}
					redirectWhenNotAuthorized={false}
					displayIntermediatePages={false}>
					<AppButton
						url={swkUrl}
						label={t('home:subapps-orderSoftwareKit')}
						icon={<ShoppingBasketIcon />}
					/>
				</Authorize>
			</Grid>
		</>
	);
};

export default SubApps;
