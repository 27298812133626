export type DrivelinePosition =
	| 'port'
	| 'port-center'
	| 'center'
	| 'starboard-center'
	| 'starboard';

const positions: DrivelinePosition[] = [
	'port',
	'port-center',
	'center',
	'starboard-center',
	'starboard',
];

export function generateDrivelinePositions(total: number): DrivelinePosition[] {
	if (total < 2) {
		return [positions[2]];
	} else if (total === 2) {
		return [positions[0], positions[4]];
	} else if (total === 3) {
		return [positions[0], positions[2], positions[4]];
	} else if (total === 4) {
		return [positions[0], positions[1], positions[3], positions[4]];
	} else if (total === 5) {
		return positions;
	}

	return [
		positions[0],
		positions[1],
		...Array(total - 4).fill(positions[2]),
		positions[3],
		positions[4],
	];
}
