import {
	PropsWithChildren,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import AccordionContext from './AccordionContext';
import { AccordionContextProps } from './types';

interface AccordionContainerProps extends PropsWithChildren {
	defaultExpanded?: string;
}

export const AccordionContainer = ({
	children,
	defaultExpanded,
}: AccordionContainerProps) => {
	const [expanded, setExpanded] = useState<string | undefined>(
		defaultExpanded
	);

	useEffect(() => {
		setExpanded(defaultExpanded);
	}, [defaultExpanded]);

	const handleOnChange = useRef((name: string) => {
		setExpanded((current) => (current !== name ? name : undefined));
	});

	const checkIfExpanded = useCallback(
		(name: string) => expanded === name,
		[expanded]
	);

	const contextValue: AccordionContextProps = useMemo(
		() => ({
			checkIfExpanded,
			onChange: handleOnChange.current,
		}),
		[checkIfExpanded]
	);

	return (
		<AccordionContext.Provider value={contextValue}>
			{children}
		</AccordionContext.Provider>
	);
};
