import { lazy } from 'react';
import { Outlet, Route } from 'react-router-dom';

import { AreaName } from 'domain/constants';
import { OperationType } from 'library/models/enums/OperationType';
import { createArea } from 'library/utils/helpers/AreaHelper';
import Authorize from 'main/auth/Authorize';

const GenerateServiceProtocolPage = lazy(
	() => import('pages/serviceprotocol/GenerateServiceProtocolPage')
);

export const getServiceProtocolRoutes = (): JSX.Element[] => [
	<Route
		key={'generateServiceProtocol'}
		path={''}
		element={
			<Authorize
				area={createArea(
					AreaName.ServiceProtocol,
					OperationType.Create
				)}>
				<Outlet />
			</Authorize>
		}>
		<Route index element={<GenerateServiceProtocolPage />} />
	</Route>,
];
