import { ProductIndividualSubSystem } from './ProductIndividualSubSystem';

export interface DrivelineSubSystem {
	id: number;
	chassisSeries: string | null;
	chassisNumber: number | null;
	position: number | null;
	warrantyNumber: string | null;
	warrantyExpirationDate: Date | null;
	restrictUCHP: boolean | null;
	operationMeasureType: string | null;
	isServiceProtocolAvailable: boolean;
	productIndividualSubSystems: Array<ProductIndividualSubSystem>;
}

export const FIRST_DRIVELINE_POSITION_NUMBER = 1;

export const byDrivelinePosition = (
	driveline1: DrivelineSubSystem,
	driveline2: DrivelineSubSystem
) => (driveline1.position ?? 0) - (driveline2.position ?? 0);

export const getChassisId = (
	driveline: Pick<DrivelineSubSystem, 'chassisSeries' | 'chassisNumber'>
) => `${driveline.chassisSeries} ${driveline.chassisNumber}`;
