import { configureStore } from '@reduxjs/toolkit';

import application from 'library/redux/application/Slice';
import commissioningFileState from 'modules/Commissioning/State/FilesSlice';
import commissioning from 'modules/Commissioning/State/Slice';
import addEndCustomer from 'modules/EndCustomer/State/Slice';
import maintenancePlanner from 'modules/MaintenancePlanner/State/Slice';
import productInOperationFiles from 'modules/ProductInOperation/State/FilesSlice';
import productInOperation from 'modules/ProductInOperation/State/Slice';
import productIndividual from 'modules/ProductIndividualview/State/Slice';
import generateOfflineServiceProtocolFiles from 'modules/ServiceProtocol/State/FilesSlice';
import generateServiceProtocol from 'modules/ServiceProtocol/State/Slice';
import unit from 'modules/Shared/State/unit/Slice';
import unitCreationFiles from 'modules/UnitCreation/State/FilesSlice';
import unitCreation from 'modules/UnitCreation/State/Slice';
import unitEdit from 'modules/UnitEdit/State/Slice';
import unitOverview from 'modules/UnitOverview/State/Slice';

export const store = configureStore({
	reducer: {
		application,
		unitCreation,
		unitCreationFiles,
		unitEdit,
		addEndCustomer,
		productInOperation,
		productInOperationFiles,
		commissioning,
		commissioningFileState,
		unitOverview,
		unit,
		maintenancePlanner,
		productIndividual,
		generateServiceProtocol,
		generateOfflineServiceProtocolFiles,
	},
	devTools: true,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
// included for backwards compatibility reasons
export type GlobalState = RootState;
