import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Edit } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { reverse } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useProductCenterApi } from 'api';
import { unitOtherPartsQuery } from 'api/queries';
import { getOtherPartId } from 'api/responses/models/Unit/UnitOtherPartsDto';
import { AreaName } from 'domain/constants';
import {
	LoadingWrapper,
	NoDataAvailable,
	PageContentTab,
	SectionTitle,
} from 'library/components/controls';
import { OperationType } from 'library/models/enums/OperationType';
import { parseId } from 'library/utils/helpers/Unit';
import AuthorizeComponent from 'main/auth/AuthorizeComponent';
import { reactPlugin } from 'main/azureInsights/config';
import { ChangedByInfoDisplay } from 'modules/UnitOverview/Components/ChangedByInfoDisplay';
import { EditProductUnitDataGrid } from 'modules/UnitOverview/Components/EditProductUnitDataGrid';
import { ProductUnitDataGrid } from 'modules/UnitOverview/Components/ProductUnitDataGrid';
import { SectionButton } from 'modules/UnitOverview/Components/SectionButton';

const OtherProductsSectionPage = (): JSX.Element => {
	const { api } = useProductCenterApi();

	const { unitId } = useParams();
	const unitIdParsed = parseId(unitId);
	const { data, isLoading } = useQuery({
		...unitOtherPartsQuery(api, unitIdParsed),
		select: (data) => ({
			...data,
			unitOtherPartDtos: data.unitOtherPartDtos.map((product) => ({
				id: getOtherPartId(product),
				...product,
			})),
		}),
	});
	const [otherProductEdit, setOtherProductEdit] = useState<boolean>(false);
	const { t } = useTranslation([
		'unitOverview',
		'commonButton',
		'unitCreation',
	]);

	const otherParts = data?.unitOtherPartDtos ?? [];
	const hasData = otherParts && otherParts.length > 0;

	return (
		<>
			{otherProductEdit ? (
				<EditProductUnitDataGrid
					otherParts={otherParts}
					unitId={unitIdParsed}
					setOtherProductEdit={setOtherProductEdit}
				/>
			) : (
				<PageContentTab>
					<SectionTitle title={t('unitCreation:wizard-step3-title')}>
						<AuthorizeComponent
							areaName={AreaName.Unit}
							permission={OperationType.Write}>
							<SectionButton
								text={t('commonButton:edit')}
								icon={<Edit />}
								onClick={() => setOtherProductEdit(true)}
							/>
						</AuthorizeComponent>
					</SectionTitle>
					<LoadingWrapper loading={isLoading}>
						{hasData ? (
							<>
								<ProductUnitDataGrid
									otherParts={reverse([...otherParts])}
									isEditMode={false}
								/>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'flex-end',
										marginTop: 3,
									}}>
									<ChangedByInfoDisplay
										changedByInfo={data?.changedBy}
									/>
								</Box>
							</>
						) : (
							<NoDataAvailable />
						)}
					</LoadingWrapper>
				</PageContentTab>
			)}
		</>
	);
};

export default withAITracking(
	reactPlugin,
	OtherProductsSectionPage,
	'UnitOverviewOtherProductsSectionPage',
	'application-insight-container'
);
