import { Collapse } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { PropsWithChildren, memo } from 'react';

import { SNACKBAR_TIMEOUT, SnackbarUtils } from './SnackbarUtils';

const Component = ({ children }: PropsWithChildren<unknown>): JSX.Element => (
	<SnackbarProvider
		maxSnack={3}
		TransitionComponent={Collapse}
		autoHideDuration={SNACKBAR_TIMEOUT}>
		<SnackbarUtils />
		{children}
	</SnackbarProvider>
);

export const SnackbarWrapper = memo(Component);
