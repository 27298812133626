import { QueryClient } from '@tanstack/react-query';

import { AreaName } from 'domain/constants';
import { ParseKeys, TOptions } from 'i18next';
import { OperationType } from 'library/models/enums/OperationType';
import { checkUserAccess } from 'main/auth/checkUserAccess';

export interface ServiceProtocolDto {
	id: number;
	serviceEvent: string | null;
	generatedServiceEvent: string | null;
	serviceDate: Date | null;
	serviceEngineRunningHours: number | null;
	servicePerformedBy: string | null;
	country: string | null;
	city: string | null;
	status: ServiceProtocolStatus;
	hasServiceContentFile: boolean | null;
	hasActivityEvents: boolean | null;
	customProtocolName: string | null;
	dealerCustomerNumber: number | null;
}

export type ServiceProtocolStatus =
	| 'Initiated'
	| 'Finalized'
	| 'Cancelled'
	| 'Removed'; // removed status is not really used, in old PC it was displayed the same as Cancelled

export const serviceProtocolStatusToTranslationMapping: {
	[key in ServiceProtocolStatus]: ParseKeys<
		['serviceProtocol'],
		TOptions,
		'serviceProtocol'
	>;
} = {
	Initiated: 'serviceProtocol:status-initiated',
	Finalized: 'serviceProtocol:status-finalized',
	Cancelled: 'serviceProtocol:status-cancelled',
	Removed: 'serviceProtocol:status-cancelled',
};

export const IsInitiated = (serviceProtocol: ServiceProtocolDto) =>
	serviceProtocol.status == 'Initiated';

export const IsFinalized = (serviceProtocol: ServiceProtocolDto) =>
	serviceProtocol.status == 'Finalized';

export const IsCancelled = (serviceProtocol: ServiceProtocolDto) =>
	serviceProtocol.status == 'Cancelled';

export const canUserDeleteProtocol = (
	serviceProtocol: ServiceProtocolDto,
	queryClient: QueryClient
) =>
	serviceProtocol.status === 'Initiated' &&
	checkUserAccess(queryClient, {
		areaName: AreaName.DeleteInitiatedProtocol,
		permission: OperationType.Delete,
	});

export const canUserCancelProtocol = (
	serviceProtocol: ServiceProtocolDto,
	queryClient: QueryClient
) =>
	serviceProtocol.status === 'Finalized' &&
	checkUserAccess(queryClient, {
		areaName: AreaName.ServiceProtocol_CancelFinalized,
		permission: OperationType.Write,
	});

export const canUserExportProtocolToExcel = (
	serviceProtocol: ServiceProtocolDto
) =>
	serviceProtocol.hasActivityEvents &&
	(serviceProtocol.status === 'Finalized' ||
		serviceProtocol.status === 'Initiated');
