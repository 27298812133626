import { OptionsObject, ProviderContext, useSnackbar } from 'notistack';
import { ReactElement } from 'react';

export const SNACKBAR_TIMEOUT = 3000;

let useSnackbarRef: ProviderContext;
export const SnackbarUtils = (): JSX.Element => {
	useSnackbarRef = useSnackbar();
	return <></>;
};

// Use these functions to access snackbar ref outside of React component tree
export const SnackbarExternal = {
	success(msg: string, options: OptionsObject = {}): void {
		this.toast(msg, { ...options, variant: 'success' });
	},
	warning(msg: string, options: OptionsObject = {}): void {
		this.toast(msg, { ...options, variant: 'warning' });
	},
	info(msg: string, options: OptionsObject = {}): void {
		this.toast(msg, { ...options, variant: 'info' });
	},
	error(msg: string, options: OptionsObject = {}): void {
		this.toast(msg, { ...options, variant: 'error' });
	},
	toast(msg: string, options: OptionsObject = {}): void {
		useSnackbarRef.enqueueSnackbar(msg, options);
	},
	closeSnackbar(key: string): void {
		useSnackbarRef.closeSnackbar(key);
	},
};

export const getCustomErrorWithAction = (
	action: ReactElement,
	duration = SNACKBAR_TIMEOUT * 2
): OptionsObject => ({
	variant: 'error',
	style: {
		maxWidth: 300,
	},
	autoHideDuration: duration,
	action,
});
