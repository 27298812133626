import { ComponentType } from 'react';

import {
	ExcelIcon,
	ImgIcon,
	PdfIcon,
	PptIcon,
	TxtIcon,
	WordIcon,
	ZipIcon,
} from 'assets/images/documents';

export const fileExtensionToFileTypeMap: { [extensions: string]: string } = {
	pdf: 'application/pdf',
	zip: 'application/zip',
	'7z': 'application/x-7z-compressed',
	rar: 'application/vnd.rar',
	txt: 'text/plain',
	doc: 'application/msword',
	docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	ppt: 'application/vnd.ms-powerpoint',
	pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	xls: 'application/vnd.ms-excel',
	xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	jpg: 'image/jpeg',
	jpeg: 'image/jpeg',
	bmp: 'image/bmp',
	webp: 'image/webp',
	png: 'image/png',
	svg: 'image/svg+xml',
};

export const fileTypeToIconMap: { [mimeType: string]: ComponentType } = {
	'application/pdf': PdfIcon,
	'application/zip': ZipIcon,
	'application/x-zip-compressed': ZipIcon,
	'application/x-7z-compressed': ZipIcon,
	'application/vnd.rar': ZipIcon,
	'application/msword': WordIcon,
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
		WordIcon,
	'text/plain': TxtIcon,
	'application/vnd.ms-powerpoint': PptIcon,
	'application/vnd.openxmlformats-officedocument.presentationml.presentation':
		PptIcon,
	'image/jpeg': ImgIcon,
	'image/bmp': ImgIcon,
	'image/webp': ImgIcon,
	'image/png': ImgIcon,
	'image/svg+xml': ImgIcon,
	'application/vnd.ms-excel': ExcelIcon,
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
		ExcelIcon,
};

export function readFile(file: File): Promise<string | ArrayBuffer | null> {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.addEventListener('load', () => resolve(reader.result), false);
		reader.readAsDataURL(file);
	});
}

export function bytesToSize(bytes: number, decimals = 2) {
	if (bytes === 0) return '0 KB';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const getFileType = (filename: string) =>
	fileExtensionToFileTypeMap[getFileExtension(filename)];

export const getFileExtension = (filename: string) =>
	filename.substring(filename.lastIndexOf('.') + 1, filename.length) ||
	filename;
